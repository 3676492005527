"use client";
import Image from "next/image";
import googlePlay from "../../../assets/page5/googlePlay.svg";
import appStore from "../../../assets/page5/appStore.svg";
import qrCode from "../../../assets/page5/QrCode.svg";
import Link from "next/link";
import useLanguage from "@/context/useLanguage";
import bgImage2 from "../../../assets/page2/bg2Web.png";

const Page5 = () => {
  const { language, translations } = useLanguage();

  return (
    <div className="bg-[#FCFCFC] py-32" id="features">
      <div className="w-4/5 mx-auto ">
        {/* Header */}
        {language === "en" ? (
          <div className="flex items-center mb-16 ">
            <div className="flex items-end justify-end w-[50%] mt-3">
              <div className="flex w-full  h-[1px]   bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
              <div className=" flex w-full h-[1px]   bg-gradient-to-r from-[#f5de6b] to-transparent" />
            </div>
            <h1 className="font-ibm-500 text-[4rem] w-full text-start">
              <span className="text-[#E9C237] font-ibm-600">
                {translations[language].section3.More}{" "}
              </span>
              {translations[language].section3.Coming}
            </h1>
          </div>
        ) : language === "ar" ? (
          <div className="flex items-center mb-10  ">
            <h1 className="font-tajawal-500 text-[4rem] w-full text-end">
              {translations[language].section3.More}
              <span className="text-[#E9C237] font-ibmArabic">
                {" "}
                {translations[language].section3.Coming}{" "}
              </span>
            </h1>
            <div className="flex items-end justify-end w-[50%] mt-3">
              <div className="flex w-full  h-[1px]   bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
              <div className=" flex w-full h-[1px]   bg-gradient-to-r from-[#f5de6b] to-transparent" />
            </div>
          </div>
        ) : (
          <div className="flex items-center mb-10 ">
            <h1 className="font-tajawal-500 text-[4rem] w-full text-end">
              <span className="text-[#E9C237] font-ibmArabic">
                {translations[language].section3.what}{" "}
              </span>
              {translations[language].section3.we}
            </h1>
            <div className="flex items-end justify-end w-[70%] mt-3">
              <div className="flex w-full  h-[1px]   bg-gradient-to-r from-[#f9e471] via-[#e5bb32] to-[#f3d859]" />
              <div className=" flex w-full h-[1px]   bg-gradient-to-r from-[#f5de6b] to-transparent" />
            </div>
          </div>
        )}
        {/* Content */}
        <div
          className="grid grid-cols-2 mx-auto "
          dir={language === "en" ? "ltr" : "rtl"}
        >
          {/* Text */}
          {language === "en" ? (
            <>
              <div className="flex flex-col items-start gap-[4.13rem]   text-5xl font-ibm-400 text-[#4C4C4C] mt-8">
                <div>
                  <p className="">
                    {translations[language].section2.StayTunedfor}{" "}
                    <span className="font-ibm-600 text-[#e9c237]">
                      {" "}
                      {translations[language].section2.MoreWays}{" "}
                    </span>
                    {translations[language].section2.to}{" "}
                    <span className="font-ibm-600 text-[#e9c237]">
                      {" "}
                      {translations[language].section2.ManageGold}.{" "}
                    </span>
                  </p>
                </div>
              </div>
            </>
          ) : language === "ar" ? (
            <>
              {" "}
              <div className="flex flex-col items-start gap-[4.4rem] mt-10 text-4xl font-ibmArabic font-normal leading-[3rem]">
                <div className="relative">
                  <p className="relative z-10 ">
                    {translations[language].section2.buy}{" "}
                    <span className=" text-[#e9c237] font-semibold">
                      {" "}
                      {translations[language].section2.GoldFractions}{" "}
                    </span>
                    {translations[language].section2.anytimeandplace}.{" "}
                    <span className="text-[#e9c237] font-semibold">
                      {" "}
                      {translations[language].section2.withease}{" "}
                    </span>
                    {translations[language].section2.Accessgoldmarkets}.{" "}
                  </p>
                  <p className="absolute -top-[2.4rem] z-0 text-[5.5rem] leading-[0.9] text-[#333]   text-opacity-[4%]">
                    01
                  </p>
                </div>
                <div className="relative">
                  <p className="relative z-10">
                    <span className="  text-[#e9c237] font-semibold">
                      {" "}
                      {translations[language].section2.track}
                    </span>{" "}
                    {translations[language].section2.your}.{" "}
                    <span className="  text-[#e9c237] font-semibold">
                      {translations[language].section2.portfolio}{" "}
                    </span>
                    {translations[language].section2.special}.
                  </p>
                  <p className="absolute -top-[2.4rem] z-0 text-[5.5rem] leading-[0.9] text-[#333]   text-opacity-[4%] ">
                    02
                  </p>
                </div>
                <div className="relative">
                  <p className="relative z-10">
                    <span className="  text-[#e9c237] font-semibold">
                      {" "}
                      {translations[language].section2.enjoy}
                    </span>{" "}
                    {translations[language].section2.complete}
                    <span className="  text-[#e9c237] font-semibold">
                      {" "}
                      {translations[language].section2.yourwallet}.
                    </span>{" "}
                    {translations[language].section2.save}{" "}
                    <span className="  text-[#e9c237] font-semibold">
                      {translations[language].section2.money}{" "}
                    </span>
                    {translations[language].section2.protected}{" "}
                    <span className="  text-[#e9c237] font-semibold">
                      {translations[language].section2.morePrivacy}.{" "}
                    </span>
                  </p>{" "}
                  <p className="absolute -top-[2.4rem] z-0 text-[5.5rem] leading-[0.9] text-[#333]   text-opacity-[4%] ">
                    03
                  </p>
                </div>
                <div className="relative">
                  <p className="relative z-10">
                    {translations[language].section2.enjoyA}{" "}
                    <span className="  text-[#e9c237] font-semibold">
                      {translations[language].section2.seamless}{" "}
                    </span>
                    {translations[language].section2.with}{" "}
                    <span className="  text-[#e9c237] font-semibold">
                      {translations[language].section2.Sabika}.{" "}
                    </span>
                  </p>
                  <p className="absolute -top-[2.4rem] z-0 text-[5.5rem] leading-[0.9] text-[#333]   text-opacity-[4%]">
                    04
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="flex flex-col items-start gap-[4.4rem] mt-8 text-4xl font-tajawal-500 ">
                <div className="relative">
                  <p className="relative z-10 ">
                    {translations[language].section2.buy}{" "}
                    <span className="  text-[#e9c237] font-tajawal-700">
                      {" "}
                      {translations[language].section2.fraction}
                    </span>
                  </p>
                  <p className="absolute -top-[2.4rem] z-0 text-[5.5rem] leading-[0.9] text-[#333]   text-opacity-[4%]">
                    01
                  </p>
                </div>
                <div className="relative">
                  <p className="relative z-10">
                    {translations[language].section2.track}{" "}
                    <span className="  text-[#e9c237] font-tajawal-700">
                      {translations[language].section2.your}{" "}
                    </span>{" "}
                    <span className="  text-[#e9c237] font-tajawal-700">
                      {translations[language].section2.portfolio}{" "}
                    </span>
                    {translations[language].section2.special}
                  </p>
                  <p className="absolute -top-[2.1rem] z-0 text-[5.5rem] leading-[0.9] text-[#333]   text-opacity-[4%] ">
                    02
                  </p>
                </div>
                <div className="relative">
                  <p className="relative z-10">
                    {translations[language].section2.buyWith}
                    <span className="  text-[#e9c237] font-tajawal-700 ">
                      {" "}
                      {translations[language].section2.safe}
                    </span>{" "}
                    {translations[language].section2.easy}{" "}
                    <span className="  text-[#e9c237] font-tajawal-700 ">
                      {translations[language].section2.management}{" "}
                    </span>
                  </p>{" "}
                  <p className="absolute -top-[2.4rem] z-0 text-[5.5rem] leading-[0.9] text-[#333]   text-opacity-[4%] ">
                    03
                  </p>
                </div>
                <div className="relative">
                  <p className="relative z-10">
                    {" "}
                    {translations[language].section2.enjoyA}{" "}
                    <span className="  text-[#e9c237] font-tajawal-700 ">
                      {" "}
                      {translations[language].section2.witheasy}{" "}
                    </span>{" "}
                    {translations[language].section2.seamless}{" "}
                  </p>
                  <p className="absolute -top-[2.4rem] z-0 text-[5.5rem] leading-[0.9] text-[#333]   text-opacity-[4%]">
                    04
                  </p>
                </div>
              </div>
            </>
          )}
          {/* Image */}
          <div
            className={`flex items-center justify-center ${
              language === "en" ? "ml-auto" : "mr-auto"
            }`}
          >
            <Image src={bgImage2} alt="bgImage2" loading="eager" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page5;
